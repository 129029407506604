<template>
  <div>
    <v-system-bar height="100" v-if="!shopIsSynced && shop != ''" class="sync-message" color="#FFE599">
      Shopify store data currently syncing
    </v-system-bar>
    <!-- Toolbar -->
    <v-app-bar color="white" flat class="toolbar" :app="!shopIsSynced  && shop != ''">
      <v-toolbar-title>{{pageTitle}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="signOut">
        Logout
        <v-icon class="logout-icon">mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- Navigation Drawer -->
    <v-navigation-drawer permanent floating app>
      <!-- Logo -->
      <div class="logo">
        <v-img src="@/assets/logos/logo.png"/>
      </div>
      <!-- Shop Select -->
      <div class="mx-4">
        <v-select :loading="loadingShops" v-model="shop" :items="shops" item-text="shop" label="Shop" outlined/>
      </div>
      <!-- Menu Itens -->
      <v-list shaped>
        <v-list-item v-for="item in items" :key="item.title" :to="item.to" link color="primary">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { getShopSyncStatus } from '@/service/api/shops'

export default {
  name: 'Toolbar',
  data() {
    return {   
      pageTitle: document.title,     
      items: [
        // {
        //   to: '/import',
        //   title: 'Import', 
        //   icon: 'mdi-file-import'
        // },

        /*
        {
          to: '/analytics',
          title: 'Analytics', 
          icon: 'mdi-google-analytics'
        },
        // {
        //   to: '/orders',
        //   title: 'Orders', 
        //   icon: 'mdi-file-document'
        // },
        // {
        //   to: '/shops',
        //   title: 'Shops', 
        //   icon: 'mdi-store'
        // },
        {
          to: '/breakdowns',
          title: 'Breakdowns', 
          icon: 'mdi-chart-arc'
        },
        // {
        //   to: '/presentation',
        //   title: 'Presentations', 
        //   icon: 'mdi-presentation'
         }*/
      ],
    }
  },
  created() {
    this.shop = this.$route.query.shop ? 
      this.$route.query.shop : 
      localStorage.getItem('shopFilter') ? localStorage.getItem('shopFilter') : ''
  },
  computed: {
    loadingShops() {
      return this.$store.state.GlobalModules.shops.loadingShops
    },
    shops() {
      return this.$store.state.GlobalModules.shops.shops
    },
    shopIsSynced() {
      return this.$store.state.GlobalModules.globalSelectShop.isSynced
    },
    shop: {
      get() {
        return localStorage.getItem('shopFilter')
      },
      set(newShop) {
        this.$store.commit('setGlobalSelectShop', newShop)
        document.getElementById('search').click()
        getShopSyncStatus(newShop).then((response) => {
          this.$store.commit('setIsSynced', response.completed)
        })
        return localStorage.setItem('shopFilter', newShop)
      }
    },
  },
  methods: {
    signOut() {     
      this.$store.dispatch('signOut')
    },
  },
  watch:{
    $route() {
      this.pageTitle = document.title
    },
  }
}
</script>

<style scoped src="./style/index.css"/>